import { application } from 'services/core'

function PrimarySection () {
  const elements = {}

  const toggleRestrictAssociationType = () => {
    toggleEditMode(true, '.toggle-edit, .toggle-edit-mrsool')

    if (!elements.addServiceType) return

    const selectedAssociationType = elements.ccAssociationType.value
    const isServiceType = selectedAssociationType === 'service_types'
    const isWebView = selectedAssociationType === 'web_view'
    const isStores = selectedAssociationType === 'stores'
    if (!isWebView) elements.webLink.value = ''
    elements.webLinkContainer.classList.toggle('hidden', !isWebView)
    elements.stores?.classList.toggle('hidden', !isStores)
    elements.serviceType?.classList.toggle('hidden', !isServiceType)
    elements.iconContainer.classList.toggle('hidden', isServiceType)
    elements.icon.required = isStores
    elements.ccServiceTypeId.required = isServiceType
    elements.ccStoreIds.required = isStores
    elements.ccNameEn.required = isStores
    elements.ccNameAr.required = isStores
    elements.webLink.required = isWebView
  }

  const initializeLogsTable = () => {
    $(elements.primarySectionLog).DataTable({
      'paging': true,
      'lengthMenu': [[10, 25, 50, 200], [10, 25, 50, 200]],
      'lengthChange': true,
      'searching': false,
      'ordering': true,
      'order': [],
      'info': true,
      'autoWidth': false
    })
  }

  const clearSearchQuery = () => {
    elements.searchQuery.value = ''
  }

  const changeActiveTab = (event) => {
    localStorage.setItem('activeTab', event.target.closest('a').getAttribute('href'))
    const componentString = event.target.textContent
    let title = ''
    switch (componentString) {
      case 'Content Component':
        title = 'Content Components'
        break
      case 'Discover Mrsool':
        title = 'Discover Mrsool'
        break
      case 'Primary Section Logs':
        title = 'Primary Section Logs'
        break
      default:
        title = 'UI Configuration'
        break
    }
    elements.pageTitle.innerHTML = title
  }

  const preSelectActiveTab = () => {
    const activeTabLink = localStorage.getItem('activeTab')
    const activeTab = $(`#primary_ui a[href="${activeTabLink}"]`)

    if (activeTabLink && activeTab[0]) {
      activeTab.tab('show')
      elements.pageTitle.innerHTML = activeTab[0].text
    }
  }

  const initializeSelectors = () => {
    elements.serviceType = document.querySelector('#service_types')
    elements.stores = document.querySelector('#stores')
    elements.webLinkContainer = document.querySelector('#web-link-container')
    elements.webLink = document.querySelector('#web-link')
    elements.iconContainer = document.querySelector('#icon-container')
    elements.icon = document.querySelector('#app_display_primary_section_content_component_icon')
    elements.ccServiceTypeId = document.querySelector('#content_component_service_type_id')
    elements.ccStoreIds = document.querySelector('#content_component_store_ids')
    elements.ccNameEn = document.querySelector('#content_component_name_en')
    elements.ccNameAr = document.querySelector('#content_component_name_ar')
    elements.ccAssociationType = document.querySelector('#content_component_association_type')
    elements.addStore = document.querySelector('#add_stores')
    elements.addServiceType = document.querySelector('#add_service_type_to_promotion')
    elements.selectedServiceType = document.querySelector('.selected-service-type-name')
    elements.submitBtn = document.querySelector('#submit_btn')
    elements.mrsoolEdit = document.querySelector('#edit_mrsool_btn')
    elements.configurationEdit = document.querySelector('#edit_btn')
    elements.displayTabs = document.querySelectorAll('.component')
    elements.pageTitle = document.querySelector('#title')
    elements.primarySectionLog = document.querySelector('#primary_section_logs')
  }

  const initializeModalSelectors = () => {
    elements.associatedStoreIds = document.querySelector('#associated_store_ids')
    elements.linkedSeviceTypeId = document.querySelector('#linked_service_type_id')
    elements.storesTable = document.querySelector('#stores_table')
    elements.associatedStoresTable = document.querySelector('#associated_stores')
    elements.linkServiceType = document.querySelector('#link_service_type')
    elements.searchQuery = document.querySelector('#search_query')
  }

  const initializeListeners = () => {
    $(elements.ccAssociationType).on('change', toggleRestrictAssociationType)
    $(elements.addStore).on('click', openStoreModal)
    $(elements.addServiceType).on('click', openServiceTypeModal)
    $(elements.submitBtn).on('click', submitForm)
    $(elements.displayTabs).on('click', changeActiveTab)
    $(elements.mrsoolEdit).on('click', { toggleClass: '.toggle-edit-mrsool' }, editBtnClick)
    $(elements.configurationEdit).on('click', { toggleClass: '.toggle-edit' }, editBtnClick)
    $(document).on('click', '.remove_from_component', removeAssociatedStores)
    $(document).on('click', '.add_to_component', associateStores)
    $(document).on('click', '#clear-search', clearSearchQuery)
    $(document).on('click', '.add_service_type_to_component', addAssociatedServiceType)
  }

  function validateEmptyNameFields (event) {
    const nameEn = elements.ccNameEn.value
    const nameAr = elements.ccNameAr.value
    if (nameEn.trim() === '' || nameAr.trim() === '') {
      event.preventDefault()
      alert('Name cannot be empty')
    }
  }

  const submitForm = (event) => {
    const associationType = elements.ccAssociationType.value
    if (elements.ccServiceTypeId.value.length === 0 && associationType === 'service_types') {
      event.preventDefault()
      alert('At least one Service type should be selected')
    } else if (elements.ccStoreIds.value.length === 0 && associationType === 'stores') {
      event.preventDefault()
      alert('At least one Store should be selected')
    } else if (associationType === 'stores') {
      validateEmptyNameFields(event)
    }
  }

  const removeAssociatedStores = (event) => {
    event.stopImmediatePropagation()
    const selectedElement = event.target

    $(elements.associatedStoresTable)
      .DataTable()
      .row(selectedElement.closest('tr'))
      .remove()
      .draw()

    $(elements.storesTable)
      .DataTable()
      .row
      .add([
        selectedElement.dataset.id,
        selectedElement.dataset.name,

        `<a href='javascript:;' class='add_to_component'
        data-id=${selectedElement.dataset.id}
        data-name=${selectedElement.dataset.name}>Add</a>`
      ])
      .draw()

    let existingStoreIds = elements.ccStoreIds.value

    if (existingStoreIds === '') {
      existingStoreIds = []
    } else {
      existingStoreIds = JSON.parse(existingStoreIds)
    }

    const itemIndex = existingStoreIds.indexOf(+selectedElement.dataset.id)

    if (itemIndex !== -1) {
      existingStoreIds.splice(itemIndex, 1)
    }

    if (existingStoreIds.length) {
      existingStoreIds = JSON.stringify(existingStoreIds)
    } else {
      existingStoreIds = ''
    }

    elements.ccStoreIds.value = existingStoreIds
    elements.associatedStoreIds.value = existingStoreIds
  }

  const associateStores = (event) => {
    const selectedElement = event.target
    let existingStoreIds = elements.ccStoreIds.value
    if (existingStoreIds === '') {
      existingStoreIds = []
    } else {
      existingStoreIds = JSON.parse(existingStoreIds)
    }
    const itemIndex = existingStoreIds.indexOf(selectedElement.dataset.id)

    $(elements.storesTable)
      .DataTable()
      .row(selectedElement.closest('tr'))
      .remove()
      .draw()

    if (itemIndex > -1) {
      return
    }

    $(elements.associatedStoresTable)
      .DataTable()
      .row
      .add([
        selectedElement.dataset.id,
        selectedElement.dataset.name,
        `<a href='javascript:;' class='remove_from_component'
          data-id=${selectedElement.dataset.id}
          data-name=${selectedElement.dataset.name}>
          Remove
         </a>`
      ])
      .draw()

    existingStoreIds.push(selectedElement.dataset.id)

    if (existingStoreIds.length) {
      existingStoreIds = JSON.stringify(existingStoreIds)
    } else {
      existingStoreIds = ''
    }

    elements.ccStoreIds.value = existingStoreIds
    elements.associatedStoreIds.value = existingStoreIds
  }

  const addAssociatedServiceType = (event) => {
    event.stopImmediatePropagation()
    const selectedElement = event.target
    const serviceTypeid = JSON.stringify([selectedElement.dataset.id])
    elements.ccServiceTypeId.value = serviceTypeid
    elements.selectedServiceType.innerHTML = selectedElement.dataset.nameEn
    $(elements.linkServiceType).modal('toggle')
  }

  const openStoreModal = (event) => {
    event.target.disabled = true
    event.target.innerHTML = 'Please wait...'

    $.ajax({
      type: 'POST',
      dataType: 'script',
      url: window.associate_stores_url,
      data: { existing_store_ids: elements.ccStoreIds.value },
      success: () => initializeModalSelectors()
    })
  }

  const openServiceTypeModal = (event) => {
    event.target.disabled = true
    event.target.innerHTML = 'Please wait...'

    $.ajax({
      type: 'POST',
      dataType: 'script',
      url: window.associate_service_type,
      data: {
        linked_service_type_id: elements.ccServiceTypeId.value,
        single_service_type_selection: true
      },
      success: () => initializeModalSelectors()
    })
  }

  const toggleEditMode = (status, toggleClass) => {
    document.querySelectorAll(toggleClass).forEach((element) => {
      element.disabled = status
    })
  }

  const editBtnClick = (event) => {
    const toggleClass = event.data.toggleClass
    toggleEditMode(false, toggleClass)
    event.target.disabled = true
  }

  return () => {
    initializeSelectors()
    initializeListeners()
    preSelectActiveTab()
    toggleRestrictAssociationType()
    initializeLogsTable()
  }
}

application.register(PrimarySection, import.meta)
export default PrimarySection
